.js-tabs {
  -ms-overflow-style: none;
  -webkit-tap-highlight-color: transparent;
}

.js-tabs__nav {
  position: relative;
  overflow: auto;
  overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
      display: none;
  }
}

.js-tabs__tabs-container {
  margin: 0;
  padding: 0;
  white-space: nowrap;
  font-size: 0;
}

.js-tabs__tab {
  display: inline-block;
  font-size: 16px;
  text-align: center;
  padding: 12px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  cursor: pointer;

  .js-tabs__no-marker > & {
    border-bottom: none;
  }
}

.js-tabs__marker {
  position: absolute;
  bottom: 0;
  height: 3px;
  transition: transform .5s, width .5s, background-color .5s;
}

.js-tabs__content-container {
  margin: 0;
  padding: 0;
}

.js-tabs__content {
  display: none;

  &.active {
    display: block;
  }
}