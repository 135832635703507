.nav {
    z-index: 10;
    position: fixed;
    top: 0;
    left: -100%;

    width: 100%;
    height: 100%;

    background-color: var(--black);
    transition: all 0.3s ease-in-out;

    overflow: hidden scroll;

    &.active {
        left: 0;
    }

    &-wrap {
        display: flex;
        align-items: center;
        justify-content: center;

        flex-direction: column;

        padding-top: 184px;
    }

    &-wrap__list {
        display: flex;
        flex-direction: column;
        
        text-align: center;
        
        gap: 30px;
    }

    &-wrap__item a {
        font-weight: 600;
        font-size: 30px;

        color: var(--gray-500);
        transition: .3s;

        &:hover, 
        &.active {
            color: var(--white);
        }
    }

    &-wrap__info {
        margin-top: 79px;

        display: flex;
        flex-direction: column;
        gap: 15px;

        width: 100%;
    }

    &-wrap__btn {
        margin: 0 10px;
    }

    &-wrap__social {
        display: flex;
        align-items: center;
        justify-content: center;

        gap: 50px;

        padding: 20px 0;
    }

    &-wrap__social-item
    a
    svg {
        display: flex;
        flex-shrink: 0;

        width: 25px;
        height: 25px;

        fill: var(--white);
        stroke: unset;
    }

    &-wrap__bg {
        position: absolute;

        top: -500px;
        left: -98px;

        width: 730.69px;
        height: 804.2px;

        z-index: -1;

        transform: rotate(169deg);
    }
}