body {
    font-weight: 300;
    font-size: 16px;
    line-height: 1.4;
    font-family: $font-main;
    color: var(--white);
    background-color: var(--black);

    @include rmin($desktop) {
        max-width: 414px;
        margin: auto;
    }

    &.lock {
        overflow: hidden;
    }
}

.lock .header--scroll {
    background-color: unset;
    backdrop-filter: unset;
}

main {
    transition: transform 0.3s ease-in, opacity 0.3s ease-in;
}

.lock main {
    transform: translate3d(280px, 0, 0);
    transition: transform 0.3s ease-in, opacity 0.3s ease-in;
}

.container {
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
    padding: unset;
}

.section {
    padding-left: 5px;
    padding-right: 5px;
}

a {
    text-decoration: none;
}


.padd {
    &--t {
        padding-top: 180px;
    }

    &--tb-small {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    &--tb-medium {
        padding-top: 65px;
        padding-bottom: 65px;
    }

    &--tb-xs {
        padding-top: 75px;
        padding-bottom: 75px;
    }
}

.width {
    &--full {
        width: 100%;
    }
}