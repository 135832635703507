.skills {
    margin-top: 65px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
        font-weight: 600;
        font-size: 28px;
    }

    &-slider {
        position: relative;

        margin-top: 25px;

        width: 100%;

        .slick-arrow {
            display: none !important;
        }

        .slick-dots {
            position: absolute;

            bottom: -35px;
        }

        .slick-dots li button:before {
            width: 12px;
            height: 12px;
            
            background-color: var(--black-600);
        }

        .slick-dots li.slick-active button:before {
            background-color: var(--gray-500);
        }

        .slick-list {
            padding: 0 10px !important;
        }

        .slick-slide {
            padding: 3px !important;
        }
    }

    &-slider__item {
        display: flex !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        padding: 25px 18px;

        height: 315px;

        background-color: var(--black-600);
        border-radius: var(--br--20);

        h2 {
            margin-top: 15px;

            font-weight: 600;
            font-size: 22px;
            line-height: 22px;
        }

        p {
            margin-top: 7px;

            padding: 0 19.5px;

            font-family: $font-second;
            font-weight: 400;
            font-size: 18px;

            text-align: center;

            color: var(--gray-400);
        }
    }

    &-slider__img-wrap {
        display: flex;
        
        width: 200px;
        height: 150px;

        img {
            width: 100%;
            height: 100%;

            object-fit: contain;
        }
    }

    &-wrap {
        margin-top: 62px;

        width: 100%;
    }

    &-wrap a {
        position: relative;

        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        padding: 35px 25px;

        background-color: var(--black-600);
        border-radius: var(--br--25);     
        aspect-ratio: 1 / 1;
        overflow: hidden;
    }

    &-wrap__info {
        z-index: 3;

        h2 {
            font-weight: 600;
            font-size: 24px;

            color: var(--white);
        }

        p {
            padding-right: 100px;
            font-weight: 500;
            font-size: 18px;

            color: var(--gray-100);
        }
    }

    &-wrap__btn {
        margin-top: 20px;

        padding: 15px 46px !important;

        width: max-content;
        height: 49px !important;

        font-size: 16px !important;

        border-radius: var(--br--15) !important;
    }

    &-wrap__img {
        position: absolute;

        width: 97%;

        bottom: 0;
        right: 0;

        z-index: 2;
    }

    &-wrap__bg {
        position: absolute;

        width: 237%;

        top: -178px;
        left: 41%;
        transform: translate(-41%);

        z-index: 1;
    }

    &-serv {
        margin-top: 35px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &-serv__title {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 15px 25px;

        width: max-content;

        border: 1.5px solid var(--black-600);
        border-radius: var(--br--50);

        h2 {
            font-weight: 500;
            font-size: 20px;

            color: var(--gray-300);            
        }
    }

    &-serv__wrap {
        margin-top: 10px;

        position: relative;

        width: 310px;
        height: 264px;

        @include rmin($phone-s) {
            width: 348px;
        }
    }

    &-serv__item {
        position: absolute;

        padding: 15px 25px;

        width: max-content;

        background-color: var(--black-600);

        border: 1.5px solid var(--black-600);
        border-radius: var(--br--50);

        span {
            font-family: $font-second;
            font-weight: 500;
            font-size: 20px;

            color: var(--gray-300);
        }

        &:nth-child(1) {
            top: 25px;
            right: 51px;

            transform: rotate(-7.53deg);
        }

        &:nth-child(2) {
            right: 0.19px;
            top: 105px;

            transform: rotate(5.96deg);
        }

        &:nth-child(3) {
            left: 0;
            top: 137px;

            transform: rotate(27.14deg);
        }

        &:nth-child(4) {
            bottom: 4px;
            right: 29px;

            transform: rotate(-15.32deg);
        }
    }

    &-serv__info {
        margin: 60px 0 35px;

        p {
            padding: 0 20px;

            font-family: $font-second;
            font-weight: 600;
            font-size: 22px;

            text-align: center;

            color: var(--gray-400);
        }
    }

    &-serv__more {
        width: 100%;

        display: flex;
        flex-direction: column;

        gap: 10px;

        span {
            text-align: center;

            font-family: $font-second;
            font-weight: 600;
            font-size: 16px;

            color: var(--gray-400);
        }
    }
}