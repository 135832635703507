.about {
    .hero {
        padding: 225px 25px 0px;
    }

    .hero-wrap__action {
        a:nth-child(2) {
            display: none;
        }
    }
    
    .hero-bg {
        top: -500px;
        left: -98px;
        width: 730.69px;
        height: 804.2px;

        transform: rotate(169deg);
        z-index: -1;
    }

    .skills-serv {
        margin-top: 0;

        &__info {
            margin: 35px 0;
        }
    }
}