.devider {
    margin: 0 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    hr {
        width: 100%;
        height: 1px;
        border: 1px solid var(--black-600);
    }

    img {
        margin: 0 25px;
    }
}