@charset "UTF-8";
:root { --black: #0C0A17; --white: #FFFFFF; --primary: #D21E34; --black-600: #24222E; --blue-500: #2F76FF; --gray-600: #464547; --gray-500: #696969; --gray-400: #8D8D8D; --gray-300: #C5C5C5; --gray-200: #676767; --gray-100: #EBEBEB; --br--3: 3px; --br--5: 5px; --br--8: 8px; --br--10: 10px; --br--12: 12px; --br--15: 15px; --br--20: 20px; --br--25: 25px; --br--30: 30px; --br--50: 50px; }

.icon-app-store { width: 1em; height: 1em; fill: initial; }

.icon-back-arrow { width: 1em; height: 1em; fill: initial; }

.icon-email { width: 1.04em; height: 1em; fill: initial; }

.icon-facebook-fill { width: 1.04em; height: 1em; fill: initial; }

.icon-google-app { width: 1em; height: 1em; fill: initial; }

.icon-ico-arrow-right { width: 0.97em; height: 1em; fill: initial; }

.icon-ico-phone { width: 1em; height: 1em; fill: initial; }

.icon-ico-whatsapp { width: 0.96em; height: 1em; fill: initial; }

.icon-subject-img { width: 1.18em; height: 1em; fill: initial; }

.icon-telegram { width: 1.04em; height: 1em; fill: initial; }

.icon-web-ico { width: 1.04em; height: 1em; fill: initial; }

.icon-whatsapp { width: 1em; height: 1em; fill: initial; }

.icon-app-store { width: 1em; height: 1em; fill: initial; }

.icon-back-arrow { width: 1em; height: 1em; fill: initial; }

.icon-email { width: 1.04em; height: 1em; fill: initial; }

.icon-facebook-fill { width: 1.04em; height: 1em; fill: initial; }

.icon-google-app { width: 1em; height: 1em; fill: initial; }

.icon-ico-arrow-right { width: 0.97em; height: 1em; fill: initial; }

.icon-ico-phone { width: 1em; height: 1em; fill: initial; }

.icon-ico-whatsapp { width: 0.96em; height: 1em; fill: initial; }

.icon-subject-img { width: 1.18em; height: 1em; fill: initial; }

.icon-telegram { width: 1.04em; height: 1em; fill: initial; }

.icon-web-ico { width: 1.04em; height: 1em; fill: initial; }

.icon-whatsapp { width: 1em; height: 1em; fill: initial; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; vertical-align: baseline; }

html { -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; }

img, fieldset, a img { border: none; }

input[type="text"], input[type="email"], input[type="tel"], textarea { -webkit-appearance: none; }

input[type="submit"], button { cursor: pointer; }

input[type="submit"]::-moz-focus-inner, button::-moz-focus-inner { padding: 0; border: 0; }

textarea { overflow: auto; }

input, button { margin: 0; padding: 0; border: 0; }

div, input, textarea, select, button, h1, h2, h3, h4, h5, h6, a, span, a:focus { outline: none; }

ul, ol, li { list-style-type: none; }

table { border-spacing: 0; border-collapse: collapse; width: 100%; }

html { box-sizing: border-box; }

*, *:before, *:after { box-sizing: inherit; }

@media (prefers-reduced-motion: reduce) { * { transition: none !important; } }

@font-face { font-family: gr; src: url("../fonts/Gilroy-Medium.woff2"); font-weight: 500; font-style: normal; font-display: swap; }

@font-face { font-family: gr; src: url("../fonts/Gilroy-Semibold.woff2"); font-weight: 600; font-style: normal; font-display: swap; }

@font-face { font-family: gr; src: url("../fonts/Gilroy-Bold.woff2"); font-weight: 700; font-style: normal; font-display: swap; }

@font-face { font-family: pn; src: url("../fonts/ProximaNova-Regular.woff2"); font-weight: 400; font-style: normal; font-display: swap; }

@font-face { font-family: pn; src: url("../fonts/ProximaNova-SemiBold.woff2"); font-weight: 600; font-style: normal; font-display: swap; }

/* Slider */
.slick-slider { position: relative; display: block; box-sizing: border-box; -moz-box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; padding: 0; }

.slick-list:focus { outline: none; }

.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list { -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

.slick-track { position: relative; left: 0; top: 0; display: block; }

.slick-track:before, .slick-track:after { content: ""; display: table; }

.slick-track:after { clear: both; }

.slick-loading .slick-track { visibility: hidden; }

.slick-slide { float: left; height: 100%; min-height: 1px; display: none; }

[dir="rtl"] .slick-slide { float: right; }

.slick-slide img { display: block; }

.slick-slide.slick-loading img { display: none; }

.slick-slide.dragging img { pointer-events: none; }

.slick-initialized .slick-slide { display: block; }

.slick-loading .slick-slide { visibility: hidden; }

.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

/* Slider */
.slick-loading .slick-list { background: #fff url("../img/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face { font-family: "slick"; src: url("../fonts/slick.eot"); src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg"); font-weight: normal; font-style: normal; }

/* Arrows */
.slick-prev, .slick-next { position: absolute; display: block; height: 20px; width: 20px; line-height: 0px; font-size: 0px; cursor: pointer; background: transparent; color: transparent; top: 50%; margin-top: -10px; padding: 0; border: none; outline: none; }

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus { outline: none; background: transparent; color: transparent; }

.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before { opacity: 1; }

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before { opacity: 0.25; }

.slick-prev:before, .slick-next:before { font-family: "slick"; font-size: 20px; line-height: 1; color: white; opacity: 0.75; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.slick-prev { left: -25px; }

[dir="rtl"] .slick-prev { left: auto; right: -25px; }

.slick-prev:before { content: "←"; }

[dir="rtl"] .slick-prev:before { content: "→"; }

.slick-next { right: -25px; }

[dir="rtl"] .slick-next { left: -25px; right: auto; }

.slick-next:before { content: "→"; }

[dir="rtl"] .slick-next:before { content: "←"; }

/* Dots */
.slick-dots { position: absolute; bottom: 22px; list-style: none; display: block; text-align: center; padding: 0; width: 100%; }

.slick-dots li { position: relative; display: inline-block; cursor: pointer; }

.slick-dots li button { display: block; padding: 3px; background: none; outline: none; line-height: 0px; font-size: 0px; color: red; cursor: pointer; }

.slick-dots li button:before { content: ""; display: block; height: 10px; width: 10px; box-sizing: border-box; border-radius: 50%; background: #979795; transition: all 0.1s ease; }

.slick-dots li button:hover:before { background: #4E4E4E; }

.slick-dots li.slick-active button:before { background: #4E4E4E; }

.js-tabs { -ms-overflow-style: none; -webkit-tap-highlight-color: transparent; }

.js-tabs__nav { position: relative; overflow: auto; overflow: -moz-scrollbars-none; }

.js-tabs__nav::-webkit-scrollbar { display: none; }

.js-tabs__tabs-container { margin: 0; padding: 0; white-space: nowrap; font-size: 0; }

.js-tabs__tab { display: inline-block; font-size: 16px; text-align: center; padding: 12px 0; border-bottom: 1px solid rgba(0, 0, 0, 0.12); cursor: pointer; }

.js-tabs__no-marker > .js-tabs__tab { border-bottom: none; }

.js-tabs__marker { position: absolute; bottom: 0; height: 3px; transition: transform .5s, width .5s, background-color .5s; }

.js-tabs__content-container { margin: 0; padding: 0; }

.js-tabs__content { display: none; }

.js-tabs__content.active { display: block; }

body { font-weight: 300; font-size: 16px; line-height: 1.4; font-family: "gr", sans-serif; color: var(--white); background-color: var(--black); }

@media only screen and (min-width: 1024px) { body { max-width: 414px; margin: auto; } }

body.lock { overflow: hidden; }

.lock .header--scroll { background-color: unset; backdrop-filter: unset; }

main { transition: transform 0.3s ease-in, opacity 0.3s ease-in; }

.lock main { transform: translate3d(280px, 0, 0); transition: transform 0.3s ease-in, opacity 0.3s ease-in; }

.container { max-width: 1140px; margin-left: auto; margin-right: auto; padding: unset; }

.section { padding-left: 5px; padding-right: 5px; }

a { text-decoration: none; }

.padd--t { padding-top: 180px; }

.padd--tb-small { padding-top: 35px; padding-bottom: 35px; }

.padd--tb-medium { padding-top: 65px; padding-bottom: 65px; }

.padd--tb-xs { padding-top: 75px; padding-bottom: 75px; }

.width--full { width: 100%; }

.header { z-index: 11; position: fixed; top: 0; left: 0; width: 100%; margin-top: 25px; padding: 10px 0; transition: top 2s ease 0s; }

.header--scroll { position: sticky; background-color: rgba(12, 10, 23, 0.8); backdrop-filter: saturate(200%) blur(10px); }

.header-wrap { display: flex; align-items: center; justify-content: space-between; padding: 0 25px; }

.header-wrap__call a { display: flex; }

.header-wrap__call a svg { display: flex; flex-shrink: 0; width: 35px; height: 35px; fill: var(--white); stroke: unset; transition: 0.1s; }

.header-wrap__call a svg.active { scale: 0; }

.header-wrap__logo a { position: relative; display: flex; flex-shrink: 0; width: 65px; height: 65px; }

.header-wrap__logo a img { width: 100%; height: 100%; }

.header-wrap__menu { position: relative; width: 35px; height: 35px; cursor: pointer; }

.header-wrap__menu::before, .header-wrap__menu::after { position: absolute; content: ""; height: 2.8px; background-color: var(--white); transition: 0.3s; }

.header-wrap__menu::before { width: 24.5px; top: 11px; right: 0; }

.header-wrap__menu::after { width: 24.5px; bottom: 11px; right: 5.6px; }

.header-wrap__menu.active::before, .header-wrap__menu.active::after { bottom: 0; left: 0; top: 15px; right: unset; width: 100%; }

.header-wrap__menu.active::before { transform: rotate(-45deg); }

.header-wrap__menu.active::after { transform: rotate(45deg); }

.nav { z-index: 10; position: fixed; top: 0; left: -100%; width: 100%; height: 100%; background-color: var(--black); transition: all 0.3s ease-in-out; overflow: hidden scroll; }

.nav.active { left: 0; }

.nav-wrap { display: flex; align-items: center; justify-content: center; flex-direction: column; padding-top: 184px; }

.nav-wrap__list { display: flex; flex-direction: column; text-align: center; gap: 30px; }

.nav-wrap__item a { font-weight: 600; font-size: 30px; color: var(--gray-500); transition: .3s; }

.nav-wrap__item a:hover, .nav-wrap__item a.active { color: var(--white); }

.nav-wrap__info { margin-top: 79px; display: flex; flex-direction: column; gap: 15px; width: 100%; }

.nav-wrap__btn { margin: 0 10px; }

.nav-wrap__social { display: flex; align-items: center; justify-content: center; gap: 50px; padding: 20px 0; }

.nav-wrap__social-item a svg { display: flex; flex-shrink: 0; width: 25px; height: 25px; fill: var(--white); stroke: unset; }

.nav-wrap__bg { position: absolute; top: -500px; left: -98px; width: 730.69px; height: 804.2px; z-index: -1; transform: rotate(169deg); }

.hero { position: relative; padding: 225px 25px 277px; overflow: hidden; }

.hero-wrap { display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 35px; }

.hero-wrap p { max-width: 325px; text-align: center; font-weight: 600; font-size: 24px; }

.hero-wrap__action { display: flex; flex-direction: column; gap: 10px; width: 100%; }

.hero-wrap__action span { display: flex; align-items: center; justify-content: center; color: var(--gray-400); }

.hero-bg { position: absolute; bottom: 1%; left: 47%; transform: translate(-47%, -1%); z-index: -1; }

.cards { margin-top: -193px; display: flex; flex-direction: column; gap: 15px; }

.cards-wrap { position: relative; }

.cards-wrap__item { position: relative; width: 100%; height: auto; aspect-ratio: 1 / 1; }

.cards-wrap__item a { position: relative; display: flex; flex-direction: column; justify-content: flex-end; padding: 20px; width: 100%; height: 100%; overflow: hidden; border-radius: var(--br--20); }

.cards-wrap__item:nth-child(1), .cards-wrap__item:nth-child(2) { position: absolute; left: 50%; transform: translate(-50%); }

.cards-wrap__item:nth-child(1) { top: -19px; z-index: 1; width: 85%; }

.cards-wrap__item:nth-child(2) { top: -10px; width: 95%; z-index: 2; }

.cards-wrap__item:nth-child(3) { z-index: 3; }

.cards-wrap__btn { flex-direction: column; box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1); border-radius: var(--br--15); z-index: 2; }

.cards-wrap__btn span:nth-child(1) { font-weight: 700; font-size: 20px; line-height: 12px; color: var(--black); }

.cards-wrap__btn span:nth-child(2) { font-family: "pn", sans-serif; font-weight: 600; font-size: 10px; color: var(--gray-200); }

.cards-wrap__item img { position: absolute; top: 0; left: 0; width: 100%; }

.cards-action { position: relative; padding: 0 10px; }

.skills { margin-top: 65px; display: flex; flex-direction: column; align-items: center; justify-content: center; }

.skills h2 { font-weight: 600; font-size: 28px; }

.skills-slider { position: relative; margin-top: 25px; width: 100%; }

.skills-slider .slick-arrow { display: none !important; }

.skills-slider .slick-dots { position: absolute; bottom: -35px; }

.skills-slider .slick-dots li button:before { width: 12px; height: 12px; background-color: var(--black-600); }

.skills-slider .slick-dots li.slick-active button:before { background-color: var(--gray-500); }

.skills-slider .slick-list { padding: 0 10px !important; }

.skills-slider .slick-slide { padding: 3px !important; }

.skills-slider__item { display: flex !important; flex-direction: column; align-items: center; justify-content: center; padding: 25px 18px; height: 315px; background-color: var(--black-600); border-radius: var(--br--20); }

.skills-slider__item h2 { margin-top: 15px; font-weight: 600; font-size: 22px; line-height: 22px; }

.skills-slider__item p { margin-top: 7px; padding: 0 19.5px; font-family: "pn", sans-serif; font-weight: 400; font-size: 18px; text-align: center; color: var(--gray-400); }

.skills-slider__img-wrap { display: flex; width: 200px; height: 150px; }

.skills-slider__img-wrap img { width: 100%; height: 100%; object-fit: contain; }

.skills-wrap { margin-top: 62px; width: 100%; }

.skills-wrap a { position: relative; display: flex; align-items: flex-start; justify-content: flex-start; padding: 35px 25px; background-color: var(--black-600); border-radius: var(--br--25); aspect-ratio: 1 / 1; overflow: hidden; }

.skills-wrap__info { z-index: 3; }

.skills-wrap__info h2 { font-weight: 600; font-size: 24px; color: var(--white); }

.skills-wrap__info p { padding-right: 100px; font-weight: 500; font-size: 18px; color: var(--gray-100); }

.skills-wrap__btn { margin-top: 20px; padding: 15px 46px !important; width: max-content; height: 49px !important; font-size: 16px !important; border-radius: var(--br--15) !important; }

.skills-wrap__img { position: absolute; width: 97%; bottom: 0; right: 0; z-index: 2; }

.skills-wrap__bg { position: absolute; width: 237%; top: -178px; left: 41%; transform: translate(-41%); z-index: 1; }

.skills-serv { margin-top: 35px; display: flex; flex-direction: column; align-items: center; justify-content: center; }

.skills-serv__title { display: flex; align-items: center; justify-content: center; padding: 15px 25px; width: max-content; border: 1.5px solid var(--black-600); border-radius: var(--br--50); }

.skills-serv__title h2 { font-weight: 500; font-size: 20px; color: var(--gray-300); }

.skills-serv__wrap { margin-top: 10px; position: relative; width: 310px; height: 264px; }

@media only screen and (min-width: 365px) { .skills-serv__wrap { width: 348px; } }

.skills-serv__item { position: absolute; padding: 15px 25px; width: max-content; background-color: var(--black-600); border: 1.5px solid var(--black-600); border-radius: var(--br--50); }

.skills-serv__item span { font-family: "pn", sans-serif; font-weight: 500; font-size: 20px; color: var(--gray-300); }

.skills-serv__item:nth-child(1) { top: 25px; right: 51px; transform: rotate(-7.53deg); }

.skills-serv__item:nth-child(2) { right: 0.19px; top: 105px; transform: rotate(5.96deg); }

.skills-serv__item:nth-child(3) { left: 0; top: 137px; transform: rotate(27.14deg); }

.skills-serv__item:nth-child(4) { bottom: 4px; right: 29px; transform: rotate(-15.32deg); }

.skills-serv__info { margin: 60px 0 35px; }

.skills-serv__info p { padding: 0 20px; font-family: "pn", sans-serif; font-weight: 600; font-size: 22px; text-align: center; color: var(--gray-400); }

.skills-serv__more { width: 100%; display: flex; flex-direction: column; gap: 10px; }

.skills-serv__more span { text-align: center; font-family: "pn", sans-serif; font-weight: 600; font-size: 16px; color: var(--gray-400); }

.projects { display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 25px; }

.projects h2 { font-weight: 600; font-size: 30px; color: var(--white); }

.projects-tabs { display: flex; flex-wrap: wrap; align-items: center; justify-content: center; gap: 7px; width: 100%; }

@media only screen and (min-width: 365px) { .projects-tabs { width: 365px; } }

.projects-tabs__item { display: flex; align-items: center; justify-content: center; padding: 10px 25px; background-color: var(--black-600); border-radius: var(--br--50); font-weight: 600; font-size: 18px; line-height: 22px; color: var(--gray-400); transition: .4s; cursor: pointer; }

.projects-tabs__item.toggled, .projects-tabs__item:hover { color: var(--white); }

.projects-tabs__item:nth-child(1n+4) { display: none; }

.projects-wrap { display: flex; flex-direction: column-reverse; align-items: center; justify-content: center; gap: 5px; width: 100%; transition: .3s; }

.projects-wrap .cards-wrap__item { position: static; width: 100%; transform: unset; }

.projects-wrap .cards-wrap__item.hidden { display: none; }

.projects-wrap .cards-wrap__item.fade { animation-name: tab-fade; animation-duration: .4s; }

.projects .devider { margin: unset; }

.projects-d { display: flex; flex-direction: column; align-items: center; justify-content: center; }

.projects-d__wrap { display: flex; flex-direction: column; align-items: center; justify-content: center; }

.projects-d__wrap h2 { margin-top: 25px; font-weight: 600; font-size: 30px; color: var(--white); }

.projects-d__wrap span { font-weight: 500; font-size: 16px; color: var(--gray-400); }

.projects-d__img { display: flex; align-items: center; justify-content: center; flex-shrink: 0; width: 150px; height: 150px; border-radius: 25px; }

.projects-d__descr { margin-top: 50px; padding: 0 20px; }

.projects-d__descr h3 { text-align: center; font-weight: 700; font-size: 24px; line-height: 29px; color: var(--white); }

.projects-d__available { margin-top: 35px; display: flex; flex-direction: column; gap: 10px; }

.projects-d__available h4 { text-align: center; font-weight: 500; font-size: 16px; color: var(--gray-400); }

.projects-d__btn.hide { display: none; }

.projects-d__about { margin-top: 50px; display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 25px; }

.projects-d__about h4 { font-weight: 600; font-size: 24px; color: var(--white); }

.projects-d__about p { text-align: center; font-family: "pn", sans-serif; font-weight: 400; font-size: 20px; color: var(--gray-300); }

.projects-d__carousel { margin-top: 25px; display: flex; align-items: center; justify-content: center; flex-direction: column; width: 100%; }

.projects-d__carousel span { margin-top: 135px; font-weight: 600; font-size: 24px; text-align: center; }

.projects-d__carousel-wrap { position: relative; display: flex; align-items: center; justify-content: center; width: 100%; }

.projects-d__carousel-wrap .slick-list { width: 100%; }

.projects-d__carousel-wrap .slick-slide div li img { display: flex; width: 100%; height: auto; object-fit: cover; }

.projects-d__carousel-wrap .slick-arrow { position: absolute; display: flex; justify-content: space-between; top: 107%; width: 100%; display: flex; align-items: center; justify-content: center; width: 60px; height: 60px; background-color: var(--black-600); border-radius: var(--br--50); z-index: 1; }

.projects-d__carousel-wrap .slick-arrow::before { content: "" !important; position: absolute; left: 0; top: 0; display: block; width: 100%; height: 100%; background: url("../../icons/ico-arrow-right.svg"); background-size: 35px 35px; background-position: center; background-repeat: no-repeat; opacity: 1; }

.projects-d__carousel-wrap .slick-arrow.slick-prev { left: 12px; }

.projects-d__carousel-wrap .slick-arrow.slick-prev::before { transform: rotate(-180deg); }

.projects-d__carousel-wrap .slick-arrow.slick-next { right: 12px; }

@media only screen and (min-width: 767px) { .projects-d__carousel-wrap .slick-arrow { top: 103%; } }

.projects-d__carousel-nav { position: relative; margin-top: 10px; display: flex; flex-direction: row; align-items: center; justify-content: center; gap: 5px; width: 210px; height: 70px; top: 107%; background-color: var(--black-600); border-radius: var(--br--50); overflow: hidden; }

.projects-d__carousel-nav .slick-list { margin: auto; display: flex; align-items: center; width: 100%; height: inherit; }

.projects-d__carousel-nav .slick-track { margin: auto; display: flex; align-items: center; justify-content: center; }

.projects-d__carousel-nav .slick-slide { margin: 0 3px; width: 35px !important; height: 36px; min-width: 15px; min-height: 15px; }

.projects-d__carousel-nav .slick-slide div li img { scale: 1; height: 36px; background-color: var(--black-600); border-radius: var(--br--25); opacity: 0.35; }

.projects-d__carousel-nav .slick-slide.slick-current { margin: 0 10px; }

.projects-d__carousel-nav .slick-slide.slick-current div li img { scale: 1.4; opacity: unset; }

.projects-d__carousel-nav .slick-arrow { display: none !important; }

.projects-d__other { display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 25px; margin-bottom: 37px; }

.projects-d__other h2 { font-weight: 600; font-size: 24px; }

.projects-d__other-list { position: relative; display: flex; align-items: center; justify-content: center; gap: 5px; overflow: scroll; }

.projects-d__other-item a { display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 10px; }

.projects-d__other-item a img { border-radius: 15px; object-fit: contain; width: 100%; height: auto; }

.projects-d__other-info { display: flex; flex-direction: column; align-items: center; justify-content: center; }

.projects-d__other-info span { font-family: "pn", sans-serif; font-weight: 500; font-size: 12px; color: var(--gray-200); }

.projects-d__other-info h3 { text-align: center; font-weight: 600; font-size: 16px; color: var(--white); }

.projects-d__other-item:nth-child(1n+3) { display: none; }

.about .hero { padding: 225px 25px 0px; }

.about .hero-wrap__action a:nth-child(2) { display: none; }

.about .hero-bg { top: -500px; left: -98px; width: 730.69px; height: 804.2px; transform: rotate(169deg); z-index: -1; }

.about .skills-serv { margin-top: 0; }

.about .skills-serv__info { margin: 35px 0; }

.contacts { position: relative; display: flex; align-items: center; justify-content: center; flex-direction: column; padding: 180px 0px 31px; overflow: hidden; }

.contacts-bg { position: absolute; top: -500px; left: -98px; width: 730.69px; height: 804.2px; z-index: -1; transform: rotate(169deg); }

.contacts h1 { display: flex; flex-direction: column; align-items: center; justify-content: center; font-size: 30px; }

.contacts__action { margin: 50px 0; display: flex; flex-direction: column; padding: 0 25px; gap: 10px; width: 100%; }

.contacts__info { display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 26px; padding: 0 25px; margin-bottom: 50px; }

.contacts__info p { font-size: 22px; text-align: center; color: var(--gray-400); }

.contacts__info-img { max-width: 500px; width: 115%; object-fit: contain; }

.btn { display: flex; align-items: center; justify-content: center; padding: 20px; gap: 5px; height: 65px; border-radius: var(--br--20); transition: 1s; font-weight: 600; font-size: 18px; text-decoration: none; }

.btn.hide { display: none; }

.btn--light { background-color: var(--white); color: var(--black); }

.btn--light:hover { background-color: var(--gray-300); }

.btn--black { background-color: var(--black-600); color: var(--white); }

.btn--black:hover { background-color: var(--gray-400); }

.btn--transparent { background-color: rgba(255, 255, 255, 0.1); color: var(--white); }

.btn--bg { background: url(../../img/bg-element.png) center 25%; border: 0.5px solid var(--gray-600); }

.devider { margin: 0 5px; display: flex; align-items: center; justify-content: center; }

.devider hr { width: 100%; height: 1px; border: 1px solid var(--black-600); }

.devider img { margin: 0 25px; }

@keyframes tab-fade { from { opacity: 0.1; }
  to { opacity: 1; } }
