.projects-d {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h2 {
            margin-top: 25px;
            font-weight: 600;
            font-size: 30px;

            color: var(--white);
        }

        span {
            font-weight: 500;
            font-size: 16px;

            color: var(--gray-400);
        }
    }

    &__img {
        display: flex;
        align-items: center;
        justify-content: center;

        flex-shrink: 0;
        
        width: 150px;
        height: 150px;

        border-radius: 25px;
    }

    &__descr {
        margin-top: 50px;
        padding: 0 20px;

        h3 {
            text-align: center;

            font-weight: 700;
            font-size: 24px;
            line-height: 29px;

            color: var(--white);
        }
    }

    &__available {
        margin-top: 35px;

        display: flex;
        flex-direction: column;
        gap: 10px;

        h4 {
            text-align: center;
            font-weight: 500;
            font-size: 16px;

            color: var(--gray-400);
        }
    }

    //
    &__btn {
        &.hide {
            display: none;
        }
    }

    &__about {
        margin-top: 50px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        gap: 25px;

        h4 {
            font-weight: 600;
            font-size: 24px;

            color: var(--white);
        }

        p {
            text-align: center;
            font-family: $font-second;
            font-weight: 400;
            font-size: 20px;

            color: var(--gray-300);
        }
    }

    &__carousel {
        margin-top: 25px;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        width: 100%;

        span {
            margin-top: 135px;
            font-weight: 600;
            font-size: 24px;
            
            text-align: center;
        }
    }

    &__carousel-wrap {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;

        .slick-list {
            width: 100%;
        }

        .slick-slide div li  img {
            display: flex;

            width: 100%;
            height: auto;

            object-fit: cover;
        }
        
        .slick-arrow {
            position: absolute;

            display: flex;
            justify-content: space-between;

            top: 107%;

            width: 100%;
            
            display: flex;
            align-items: center;
            justify-content: center;

            width: 60px;
            height: 60px;

            background-color: var(--black-600);
            border-radius: var(--br--50);

            z-index: 1;

            &::before {
                content: "" !important;

                position: absolute;
                left: 0;
                top: 0;
                display: block;
                width: 100%;
                height: 100%;

                background: url('../../icons/ico-arrow-right.svg');
                background-size: 35px 35px;
                background-position: center;
                background-repeat: no-repeat;
                opacity: 1;
            }

            &.slick-prev {
                left: 12px;

                &::before {
                    transform: rotate(-180deg);
                }
            }

            &.slick-next {
                right: 12px;
            }

            @include rmin($tablet) {
                top: 103%;
            }
        }
    }

    &__carousel-nav {
        position: relative;

        margin-top: 10px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        gap: 5px;

       // padding: 0 20px;

        width: 210px;
        height: 70px;

        top: 107%;

        background-color: var(--black-600);
        border-radius: var(--br--50);
        overflow: hidden;

        .slick-list {
            margin: auto;

            display: flex;
            align-items: center;
            // justify-content: center;

            width: 100%;
            height: inherit;
        }

        .slick-track {
            margin: auto;

            display: flex;
            align-items: center;
            justify-content: center;
        }

        .slick-slide {
            margin: 0 3px;

            width: 35px !important;
            height: 36px;

            min-width: 15px;
            min-height: 15px;
        }

        .slick-slide div li img {
            scale: 1;

            height: 36px;

            background-color: var(--black-600);
            border-radius: var(--br--25);

            opacity: 0.35;
        }

        .slick-slide.slick-current {
            margin: 0 10px;
        }

        .slick-slide.slick-current div li img {
            scale: 1.4;

            opacity: unset;
        }

        .slick-arrow {
            display: none !important;
        }
    }

    &__other {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        gap: 25px;

        margin-bottom: 37px;

        h2 {
            font-weight: 600;
            font-size: 24px;
        }
    }

    &__other-list {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;

        gap: 5px;
        
        overflow: scroll;
    }

    &__other-item a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        gap: 10px;

        img {
            border-radius: 15px;
            object-fit: contain;

            width: 100%;
            height: auto;
        }
    }

    &__other-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        span {
            font-family: $font-second;
            font-weight: 500;
            font-size: 12px;

            color: var(--gray-200);
        }

        h3 {
            text-align: center;
            font-weight: 600;
            font-size: 16px;

            color: var(--white);
        }
    }

    &__other-item:nth-child(1n+3) {
        display: none;
    }
}