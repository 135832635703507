// breakpoints
$phone-s: 365;
$phone-m: 414;
$tablet: 767;
$desktop: 1024;

// fonts
$font-main: "gr", sans-serif;
$font-second: "pn", sans-serif;
$font-fontello: "fontello";
$font-slick: "slick";

$max-width: 1140px;

:root {
  --black: #0C0A17;
  --white: #FFFFFF;

  --primary: #D21E34;

  --black-600: #24222E;

  --blue-500: #2F76FF;

  --gray-600: #464547;
  --gray-500: #696969;
  
  --gray-400: #8D8D8D;
  --gray-300: #C5C5C5;
  --gray-200: #676767;
  --gray-100: #EBEBEB;

  // border radius
  --br--3: 3px;
  --br--5: 5px;
  --br--8: 8px;
  --br--10: 10px;
  --br--12: 12px;
  --br--15: 15px;
  --br--20: 20px;
  --br--25: 25px;
  --br--30: 30px;
  --br--50: 50px;
}