.projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 25px;

    h2 {
        font-weight: 600;
        font-size: 30px;

        color: var(--white);
    }

    &-tabs {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        gap: 7px;

        width: 100%;

        @include rmin($phone-s) {
            width: 365px;
        }
    }

    &-tabs__item {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 10px 25px;

        background-color: var(--black-600);
        border-radius: var(--br--50);

        font-weight: 600;
        font-size: 18px;
        line-height: 22px;

        color: var(--gray-400);

        transition: .4s;
        cursor: pointer;

        &.toggled,
        &:hover {
            color: var(--white);
        }

        // 
        &:nth-child(1n+4) {
            display: none;
        }
    }

    &-wrap {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;

        gap: 5px;

        width: 100%;

        transition: .3s;
    }

    &-wrap .cards-wrap__item {
        position: static;

        width: 100%;

        transform: unset;

        &.hidden {
            display: none;
        }

        &.fade {
            animation-name: tab-fade;animation-duration: .4s;
        }        
    }

    .devider {
        margin: unset;
    }
}