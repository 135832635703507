.icon-app-store {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-back-arrow {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-email {
  width: 1.04em;
  height: 1em;
  fill: initial;
}
.icon-facebook-fill {
  width: 1.04em;
  height: 1em;
  fill: initial;
}
.icon-google-app {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-ico-arrow-right {
  width: 0.97em;
  height: 1em;
  fill: initial;
}
.icon-ico-phone {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-ico-whatsapp {
  width: 0.96em;
  height: 1em;
  fill: initial;
}
.icon-subject-img {
  width: 1.18em;
  height: 1em;
  fill: initial;
}
.icon-telegram {
  width: 1.04em;
  height: 1em;
  fill: initial;
}
.icon-web-ico {
  width: 1.04em;
  height: 1em;
  fill: initial;
}
.icon-whatsapp {
  width: 1em;
  height: 1em;
  fill: initial;
}
