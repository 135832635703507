.hero {
    position: relative;

    padding: 225px 25px 277px;

    overflow: hidden;

    &-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        gap: 35px;
    }

    &-wrap p {
        max-width: 325px;

        text-align: center;

        font-weight: 600;
        font-size: 24px;
    }

    &-wrap__action {
        display: flex;
        flex-direction: column;

        gap: 10px;

        width: 100%;

        span {
            display: flex;
            align-items: center;
            justify-content: center;

            color: var(--gray-400);
        }
    }

    &-bg {
        position: absolute;

        bottom: 1%;
        left: 47%;
        transform: translate(-47%, -1%);

        z-index: -1;
    }
}