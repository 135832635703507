.contacts {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: 180px 0px 31px;
    overflow: hidden;

    &-bg {
        position: absolute;

        top: -500px;
        left: -98px;

        width: 730.69px;
        height: 804.2px;

        z-index: -1;

        transform: rotate(169deg);
    }

    h1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        font-size: 30px;
    }

    &__action {
        margin: 50px 0;
        display: flex;
        flex-direction: column;

        padding: 0 25px;

        gap: 10px;
        width: 100%;
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        gap: 26px;

        padding: 0 25px;

        margin-bottom: 50px;

        p {
            font-size: 22px;
            text-align: center;

            color: var(--gray-400);
        }
    }

    &__info-img {
        max-width: 500px;
        width: 115%;

        object-fit: contain;
    }
}