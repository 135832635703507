.btn {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 20px;
    gap: 5px;

    height: 65px;

    border-radius: var(--br--20);
    transition: 1s;

    //
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;

    &.hide {
        display: none;
    }

    &--light {
        background-color: var(--white);

        color: var(--black);

        &:hover {
            background-color: var(--gray-300);
        }
    }

    &--black {
        background-color: var(--black-600);

        color: var(--white);

        &:hover {
            background-color: var(--gray-400);
        }
    }

    &--transparent {
        background-color: rgba(255, 255, 255, 0.1);

        color: var(--white);
    }

    &--bg {
        background: url(../../img/bg-element.png) center 25%;
        border: 0.5px solid var(--gray-600);
    }
}