.header {
    z-index: 11;
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    margin-top: 25px;
    padding: 10px 0;
    transition: top 2s ease 0s;
    
    &--scroll {
        position: sticky;
        background-color: rgba(12, 10, 23, 0.8);
        backdrop-filter: saturate(200%) blur(10px);
    }

    &-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 0 25px;
    }

    &-wrap__call {
        a {
            display: flex;
        }
    }
    &-wrap__call a svg {
        display: flex;
        flex-shrink: 0;

        width: 35px;
        height: 35px;

        fill: var(--white);
        stroke: unset;

        transition: 0.1s;

        &.active {
            scale: 0;
        }
    }

    &-wrap__logo a {
        position: relative;

        display: flex;
        flex-shrink: 0;

        width: 65px;
        height: 65px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    &-wrap__menu {
        position: relative;

        width: 35px;
        height: 35px;

        cursor: pointer;

        &::before,
        &::after {
            position: absolute;
            content: "";

            height: 2.8px;

            background-color: var(--white);
            transition: 0.3s;
        }

        &::before {
            width: 24.5px;

            top: 11px;
            right: 0;
        }

        &::after {
            width: 24.5px;

            bottom: 11px;
            right: 5.6px;
        }

        &.active {
            &::before,
            &::after {
                bottom: 0;
                left: 0;
                top: 15px;
                right: unset;

                width: 100%;
            }

            &::before {
                transform: rotate(-45deg);
            }

            &::after {
                transform: rotate(45deg);
            }
        }
    }
}
