.cards {
    margin-top: -193px;

    display: flex;
    flex-direction: column;

    gap: 15px;

    &-wrap {
        position: relative;
    }

    &-wrap__item {
        position: relative;

        width: 100%;
        height: auto;
        aspect-ratio: 1 / 1;
    }

    &-wrap__item a {
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        padding: 20px;

        width: 100%;
        height: 100%;

        overflow: hidden;

        border-radius: var(--br--20);
    }

    &-wrap__item:nth-child(1),
    &-wrap__item:nth-child(2) {
        position: absolute;

        left: 50%;
        transform: translate(-50%);
    }

    &-wrap__item:nth-child(1) {        
        top: -19px;

        z-index: 1;

        width: 85%;
    }

    &-wrap__item:nth-child(2) {
        top: -10px;
        
        width: 95%;

        z-index: 2;
    }

    &-wrap__item:nth-child(3) {
        z-index: 3;
    }

    &-wrap__btn {
        flex-direction: column;

        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
        border-radius: var(--br--15);

        z-index: 2;
    }

    &-wrap__btn {
        span:nth-child(1) {
            font-weight: 700;
            font-size: 20px;
            line-height: 12px;
            
            color: var(--black);
        }

        span:nth-child(2) {
            font-family: $font-second;
            font-weight: 600;
            font-size: 10px;
            
            color: var(--gray-200);
        }
    }

    &-wrap__item img {
        position: absolute;

        top: 0;
        left: 0;

        width: 100%;
    }

    &-action {
        position: relative;

        padding: 0 10px;
    }
}